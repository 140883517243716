const listOfPairs: CompoundWord[] = [
  ["advent", "calendar"],
  ["anger", "management"],
  ["chocolate", "milk"],
  ["christmas", "carols"],
  ["christmas", "decoration"],
  ["christmas", "present"],
  ["christmas", "stockings"],
  ["christmas", "tree"],
  ["climate", "change"],
  ["consumption", "trap"],
  ["digestive", "schnapps"],
  ["epiphany", "kings"],
  ["fairy", "lights"],
  ["Family", "disputes"],
  ["gift", "exchange"],
  ["holiday", "decoration"],
  ["hot", "chocolate"],
  ["mistletoe", "kiss"],
  ["mulled", "wine"],
  ["north", "pole"],
  ["reindeer", "sleigh"],
  ["road", "grit"],
  ["saint", "nicholas"],
  ["santa", "claus"],
  ["snow", "chaos"],
  ["social", "distancing"],
  ["stomach", "pain"],
  ["winter", "tires"],
];

export default listOfPairs;
